.fc .fc-col-header-cell-cushion {
  color: #004f71;
  /* needs to be same precedence */
  font-family: 'HelveticaNeue';
  font-weight: 300;
  font-size: 16px;
}

.fc .fc-toolbar-title {
  color: #004f71;
  font-size: 1.5rem;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 10px;
}

/* Header Buttons */
.fc .fc-button-primary.fc-today-button {
  font-family: 'HelveticaNeue';
  font-weight: 300;
  border: none;
  font-size: 1rem;
  text-transform: capitalize;
  background-color: #004f71;
  border-radius: 10px;
  padding: 2px 18px;
}
.fc .fc-button-primary.fc-today-button:active {
  background-color: #004f71;
}
.fc .fc-button-primary.fc-today-button:not(:disabled):hover {
  background-color: #97d700;
}

/* Prev Button */
.fc .fc-button-primary.fc-prev-button {
  box-shadow: none;
  font-family: 'HelveticaNeue';
  font-weight: 300;
  border: none;
  background-color: transparent;
  color: #004f71;
}

.fc .fc-button-primary.fc-prev-button:hover {
  color: #97d700;
}

.fc .fc-button-primary.fc-prev-button:focus,
:active {
  box-shadow: none;
  background-color: transparent;
}

.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}

/* Next Button */
.fc .fc-button-primary.fc-next-button {
  box-shadow: none;
  font-family: 'HelveticaNeue';
  font-weight: 300;
  border: none;
  background-color: transparent;
  color: #004f71;
}

.fc .fc-button-primary.fc-next-button:hover {
  color: #97d700;
}

.fc .fc-button-primary.fc-next-button:focus,
:active {
  box-shadow: none;
  background-color: transparent;
}

.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}
/* End Header Buttons */

/* Day Styles */
.fc .fc-daygrid-day-number {
  font-size: 14px;
  color: #008eaa;
}

.fc .fc-bg-event {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
/* Event Styles */
.fc .fc-bg-event .fc-event-title {
  margin: 2px;
  font-size: 0.75rem;
  text-align: center;
  color: #867f7f;
  overflow: hidden;
}

.fc-h-event .fc-event-title {
  font-size: 14px;
  display: block;
}

.fc .fc-bg-event {
  opacity: var(--fc-bg-event-opacity, 1);
}

@media only screen and (max-width: 600px) {
  .fc .fc-col-header-cell-cushion {
    font-size: 12px;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 5px;
  }

  .fc .fc-toolbar-title {
    font-size: 1rem;
  }

  .fc .fc-button-primary.fc-today-button {
    font-family: 'HelveticaNeue';
    font-weight: 300;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 5px;
  }

  /* Day Styles */
  .fc .fc-daygrid-day-number {
    font-size: 12px;
  }

  /* Event Styles */
  .fc .fc-bg-event .fc-event-title {
    font-size: 9px;
  }

  .fc-h-event .fc-event-title {
    font-size: 9px;
    /* display: block; */
  }
}
